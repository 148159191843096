<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12" v-if="pageLoaded">
      <v-card-title style="background-color: transparent !important">
        <h3>
          پرونده {{ userData.user.name }} ({{ userData.user.role }})
          {{ userData.user.natCode ? "/ " + userData.user.natCode : "" }}
        </h3>
      </v-card-title>
      <v-tabs
        class="mt-1"
        background-color="transparent"
        vertical
        v-model="tab"
        :style="
          deviceType == 'mobile'
            ? 'border-bottom : 1px solid rgba(0, 0, 0, 0.1) !important'
            : ''
        "
      >
        <v-tab class="tab-title first-tab-item-br"> اطلاعات کارمند </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          ویرایش اطلاعات کارمند
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          خدمات
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          ساعات کارکرد
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >لیست مرخصی‌ها</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >پرداختی‌های کارمند
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >دریافتی‌‌های کارمند
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >تسویه حساب</v-tab
        >

        <v-tab class="tab-title" style="border-radius: 0 !important"
          >فیش حقوقی</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >معاینات بدو استخدام</v-tab
        >
        <v-tab class="tab-title last-tab-item-br">بایگانی فایل‌ها</v-tab>

        <v-tabs-items v-model="tab">
          <!-- اطلاعات کارمند -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <person-info :job="'servant'" :userData="userData.user" />
            </v-card>
          </v-tab-item>
          <!-- ویرایش اطلاعات عمومی کارمند -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <edit-person
                :job="'servant'"
                @getInfo="showEmpInfo()"
                :userData="userData.user"
              />
            </v-card>
          </v-tab-item>
          <!-- خدمات -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <person-activity :userId="userId" />
            </v-card>
          </v-tab-item>
          <!-- ساعات کارکرد -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <person-activity-time :userId="userId" />
            </v-card>
            <!--<v-card class="pa-2">
                    <v-row class="time-row">
                      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="activityDateFrom">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="dateFrom"
                            label=" تاریخ از "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="dateFrom"
                          element="activityDateFrom"
                          color="#00a7b7"
                        /> </v-col
                      ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <span id="activityDateTo">
                          <v-text-field
                            outlined
                            dense
                            type="text"
                            append-icon="calendar_today"
                            v-model="dateTo"
                            label=" تاریخ تا "
                            :editable="true"
                            class="date-input"
                          >
                          </v-text-field>
                        </span>

                        <date-picker
                          v-model="dateTo"
                          element="activityDateTo"
                          color="#00a7b7"
                        />
                      </v-col>
                      <v-row class="mb-4 mx-1">
                        <v-col>
                          <v-btn
                            class="submit-btn primary-btn"
                            style="float: right"
                            @click="getUserActivities()"
                            :disabled="Busy"
                            >اعمال</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-row>
                    <v-text-field
                      v-model="Filter"
                      prepend-inner-icon="mdi-magnify"
                      label="جستجو"
                      single-line
                      hide-details
                      clearable
                      rounded
                      filled
                      class="text-right w-80 mb-2 search-input"
                    ></v-text-field>
                    <b-table
                      responsive
                      show-empty
                      :fields="Fields"
                      :items="userActivities"
                      empty-text="رکوردی برای نمایش وجود ندارد"
                      empty-filtered-text="رکوردی برای نمایش یافت نشد"
                      :busy="Busy"
                      :filter="Filter"
                      :current-page="CurrentPage"
                      :per-page="PerPage"
                      @filtered="onFiltered"
                    >
                      <template v-slot:head()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.label }}
                        </div>
                      </template>
                      <template v-slot:cell()="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.value }}
                        </div>
                      </template>
                      <template v-slot:cell(index)="data">
                        <div style="text-align: center; vertical-align: middle">
                          {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                        </div>
                      </template>
                      <template v-slot:cell(operation)="data">
                        <div style="text-align: center; vertical-align: middle">
                          <v-btn
                            class="primary-btn"
                            @click="gotoEditService(data.item)"
                            ><v-icon> edit</v-icon></v-btn
                          >
                        </div>
                      </template>
                      <div
                        slot="table-busy"
                        class="text-center primary--text my-2"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          class="align-middle"
                        ></v-progress-circular>
                      </div>
                    </b-table>
                    <v-pagination
                      v-model="CurrentPage"
                      :length="Math.ceil(TotalRows / PerPage)"
                      :total-visible="5"
                      prev-icon="arrow_back"
                      next-icon="arrow_forward"
                      style="float: center"
                    ></v-pagination>
                  </v-card>-->
          </v-tab-item>
          <!-- لیست مرخصی‌ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- person leave list part has been componented -->
              <person-leave-list
                :leaves="leavesList"
                @getInfo="showEmpInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- لیست پرداخت‌ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- person payment part has been componented -->
              <person-payments
                :payments="paymentsList"
                @getInfo="showEmpInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- لیست دریافت ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- person receives part has been componented -->
              <person-receives
                :receives="receivesList"
                @getInfo="showEmpInfo()"
              />
            </v-card>
          </v-tab-item>

          <!-- تسویه حساب -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <person-checkout
                :checkOut="checkOut"
                :userData="userData.user"
                :job="'کارمند خدمات'"
                @getInfo="showEmpInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- فیش حقوقی -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 650px">
              <!-- person payment part has been componented -->
              <person-pay-slip
                :paySlips="paySlips"
                :userData="userData.user"
                @getInfo="showEmpInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- معاینات استخدام -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- person payment part has been componented -->
              <employment-tests :userData="userData.user" />
            </v-card>
          </v-tab-item>
          <!-- لیست فایلها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- files part has been componented -->
              <person-files :files="filesList" @getInfo="showEmpInfo()" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
const EditPerson = () => import("@/components/hr/EditPerson");
const personLeaveList = () => import("@/components/hr/personLeaveList");
const PersonCheckout = () => import("@/components/hr/PersonCheckout");
const PersonFiles = () => import("@/components/hr/PersonFiles");
const PersonPayments = () => import("@/components/hr/PersonPayments");
const PersonReceives = () => import("@/components/hr/PersonReceives");
const personActivityTime = () => import("@/components/hr/personActivityTime");
const personActivity = () => import("@/components/hr/personActivity");
const personInfo = () => import("@/components/hr/PersonInfo");
const PersonPaySlip = () => import("@/components/hr/PersonPaySlip");
const EmploymentTests = () => import("@/components/hr/EmploymentTests");

export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    EditPerson,
    personLeaveList,
    PersonCheckout,
    PersonFiles,
    PersonPayments,
    PersonReceives,
    personActivityTime,
    personActivity,
    personInfo,
    PersonPaySlip,
    EmploymentTests,
  },
  data() {
    return {
      tab: null,
      dateFrom: moment(new Date()).subtract(7, "day").format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00:00",
      timeTo: "23:59:59",
      userData: [],
      userActivities: [],
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: 1,
      Filter: "",
      Busy: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "subject", label: "نام شیفت" },
        //{ key: "docId", label: "پزشک شیفت" },
        //{ key: "nurseId", label: "پرستار شیفت" },
        { key: "startTime", label: "تاریخ و ساعت آغاز " },
        { key: "endTime", label: "تاریخ و ساعت پایان" },
        { key: "duration", label: "مدت زمان فعالیت" },
      ],
      excelFields: [
        { key: "subject", label: "نام شیفت" },
        { key: "docId", label: "پزشک شیفت" },
        { key: "nurseId", label: "پرستار شیفت" },
        { key: "startTime", label: "تاریخ و ساعت آغاز " },
        { key: "endTime", label: "تاریخ و ساعت پایان" },
        { key: "duration", label: "مدت زمان فعالیت" },
      ],
      Items: [],
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      pageLoaded: false,
      leavesList: [],
      filesList: [],
      paymentsList: [],
      receivesList: [],
      deviceType: "",
      checkOut: {},
      userId: "",
      paySlips: [],
    };
  },
  methods: {
    showEmpInfo() {
      //NOTE getting employee's info from server and set it in user data
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employee/getInfo",
          {
            userId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.userData.user = res.data.user;
            this.leavesList = res.data.leaves;
            this.filesList = res.data.files;
            this.paymentsList = res.data.payments;
            this.receivesList = res.data.receives;
            this.checkOut = res.data.checkOut;
            this.paySlips = res.data.payslips;
            this.vLoading = false;

            setTimeout(() => {
              this.pageLoaded = true;
            }, 200);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    //getUserActivities() {
    //  //NOTE getting servant's acrivity info
    //  this.Busy = true;
    //  this.$http
    //    .post(
    //      this.baseUrl + "/clinic/hr/employee/activityTime",
    //      {
    //        userId: this.$route.params.id,
    //        startDate: this.dateFrom,
    //        endDate: this.dateTo,
    //      },
    //      {
    //        headers: {
    //          Authorization: "Bearer: " + localStorage.getItem("token"),
    //        },
    //      }
    //    )
    //    .then((res) => {
    //      if (res.status == 200) {
    //        this.userActivities = res.data;
    //        this.TotalRows = this.userActivities.length;
    //        this.Busy = false;
    //      } else {
    //        this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
    //        this.Busy = false;
    //      }
    //    })
    //    .catch((err) => {
    //      this.toast("خطا: " + err.response.data, "error");
    //      this.Busy = false;
    //    });
    //},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },

  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    let previousPage = localStorage.getItem("previousPage");
    localStorage.removeItem("previousPage");
    if (previousPage == "newPaySlip") {
      this.tab = 8;
    }
    this.userId = this.$route.params.id;
    this.showEmpInfo();
  },
};
</script>
<style>
@media only screen and (max-width: 600px) {
  .v-tabs--vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .v-tab {
    border-bottom: 1px solid #ccc;
  }
  .v-window {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .v-window {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
.v-tabs-slider {
  display: none;
}
</style>
